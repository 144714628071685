/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import clsx from "clsx";
import { AnimatePresence, m } from "framer-motion";
import { useAppState } from "@state";

const PageContainer = ({
  children,
  className: _className,
  flex,
  pageContext,
  meta,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [, dispatch] = useAppState();
  const { path } = pageContext || {};

  const variants = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <m.main
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.6,
      }}
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className={clsx(
        "min-h-screen flex-grow overflow-x-clip",
        {
          "flex flex-col": flex,
        },
        _className
      )}
    >
      <AnimatePresence exitBeforeEnter initial>
        {children}
      </AnimatePresence>
    </m.main>
  );
};

PageContainer.defaultProps = {
  className: "",
  flex: true,
};

export default PageContainer;
