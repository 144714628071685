import "./src/pcss/index.pcss";
import { AnimatePresence } from "framer-motion";

import React from "react";
import { Default } from "@layout";
import ErrorBoundary from "@organisms/ErrorBoundary";
import { AppStateProvider } from "@state";

export const wrapPageElement = ({ element, props }) => (
  <Default pageContext={props.pageContext}>
    <AnimatePresence exitBeforeEnter initial={false}>
      {element}
    </AnimatePresence>
  </Default>
);

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <AppStateProvider>{element}</AppStateProvider>
    </ErrorBoundary>
  );
};
