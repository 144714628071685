import React from "react";
import { AppLink } from "@base";
import clsx from "clsx";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
}) => {
  const defaultClassName = `inline-flex justify-center items-center relative z-0 font-sans uppercase font-bold group leading-tighter tracking-wide transition duration-300`;

  const sizes = {
    xs: "p-2 text-xs",
    sm: "px-4 py-2 text-sm",
    md: "px-5 py-3 text-base",
    lg: "px-6 py-4 text-lg",
  };

  const colors = {
    "carnation-light":
      "bg-carnation hover:bg-mint text-white hover:text-midnight",
    carnation: "bg-carnation hover:bg-midnight text-white",
    mint: "bg-mint hover:bg-midnight text-midnight hover:text-white",
    midnight: "bg-midnight hover:bg-carnation text-white",
    white: "border border-white text-white hover:bg-white hover:text-inherit",
  };

  const classes = clsx(
    defaultClassName,
    sizes[size] || "px-5 py-3 text-base",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        <span className="relative z-10 text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
    >
      <span className="relative z-10 text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "carnation",
  title: null,
  type: "button",
  onClick: null,
  size: "md",
  to: null,
};

export default Button;
