import React from "react";
import clsx from "clsx";
import { unwidower } from "@utils";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag,
  dewidow,
}) => {
  let Tag = tag;
  let classes;

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-heading font-bold text-4xl md:text-5xl lg:text-6xl leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes =
        "font-heading font-bold text-3.5xl md:text-4.5xl lg:text-5.5xl leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes =
        "font-heading font-bold text-2.5xl md:text-3.5xl lg:text-4.5xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes =
        "font-heading font-bold text-1.5xl md:text-2.5xl lg:text-3.5xl leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes =
        "font-heading font-bold text-xl md:text-1.5xl lg:text-2.5xl leading-tighter";
      break;
    case "h6":
      Tag = "h6";
      classes =
        "font-heading font-bold text-lg md:text-xl lg:1.5xl leading-tight";
      break;
    case "h7":
      classes = "font-heading font-bold text-sm md:text-base leading-tight";
      break;
    case "h2sans":
      Tag = "h2";
      classes =
        "font-sans text-3xl md:text-4.5xl leading-tighter uppercase tracking-wide";
      break;
    case "h3sans":
      Tag = "h3";
      classes =
        "font-sans text-2xl md:text-3.5xl leading-tighter uppercase tracking-wide";
      break;
    case "h4sans":
      Tag = "h4";
      classes =
        "font-sans text-1.5xl md:text-3xl leading-tighter uppercase tracking-wide";
      break;
    case "h5sans":
      Tag = "h5";
      classes =
        "font-sans text-xl md:text-2.5xl leading-tighter uppercase tracking-wide";
      break;
    case "h6sans":
      Tag = "h6";
      classes =
        "font-sans text-lg md:text-xl leading-tighter uppercase tracking-wide";
      break;
    case "xl":
      classes =
        "block text-lg md:text-xl leading-normal tracking-tight font-serif";
      break;
    case "lg":
      classes =
        "block text-base md:text-lg leading-normal tracking-tight font-serif";
      break;
    case "body":
      classes =
        "block text-sm md:text-base leading-normal tracking-tight font-serif";
      break;
    case "sm":
      classes = "block text-sm leading-normal tracking-tight font-serif";
      break;
    case "xs":
      classes = "block text-xs leading-normal tracking-tight font-serif";
      break;
    case "xxs":
      classes = "block text-xxs leading-normal tracking-tight font-serif";
      break;
    case "caption":
      classes = "block text-xs leading-normal tracking-tight font-serif";
      break;
    case "label":
      classes =
        "block text-base uppercase font-sans font-bold leading-none tracking-wide";
      break;
    case "linkLg":
      classes =
        "text-lg font-bold leading-none font-sans uppercase tracking-wider";
      break;
    case "link":
      classes =
        "text-base font-bold leading-none font-sans uppercase tracking-wider";
      break;
    case "linkSm":
      classes =
        "text-sm font-bold leading-none font-sans uppercase tracking-wider";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={`text--${i}`}
          className={clsx(classes, _className, {
            quotes,
            "prose prose-sm md:prose-base": richText,
          })}
          dangerouslySetInnerHTML={{ __html: unwidower(child, dewidow) }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={`text--${i}`}
        className={clsx(classes, _className, {
          quotes,
          "prose prose-sm md:prose-base": richText,
        })}
      >
        {unwidower(child, dewidow)}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: false,
  quotes: false,
  children: null,
  dewidow: false,
};

export default Text;
