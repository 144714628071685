import React, { useEffect } from "react";
// import { useBrowserTheme } from "@utils";
import { PageSizeObserver } from "@base";
import loadable from "@loadable/component";
import { LazyMotion } from "framer-motion";

import { SITE } from "@constants";
import BrowserWarning from "../atoms/BrowserWarning";

// import lightIcon from "../../../static/images/icon_light.png";
// import darkIcon from "../../../static/images/icon.png";

const Announcement = loadable(() => import("@organisms/Announcement"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Header = loadable(() => import("@organisms/Header"));
const HeaderRA = loadable(() => import("@organisms/HeaderRA"));
const Footer = loadable(() => import("@organisms/Footer"));
const FooterRA = loadable(() => import("@organisms/FooterRA"));
const Modal = loadable(() => import("@organisms/Modal"));
const Userback = loadable(() => import("@organisms/Userback"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, pageContext }) => {
  const { uri } = pageContext;
  useEffect(() => {
    resolve(import("../../utils/animationFeatures").then(r => r.domMax));
  }, []);
  // const theme = useBrowserTheme();
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div id="app_layout">
        <PageSizeObserver />
        <Announcement />
        {SITE === "default" && <Header />}
        {SITE === "robActon" && <HeaderRA home={uri === "home"} />}
        {children}
        {SITE === "default" && <Footer />}
        {SITE === "robActon" && <FooterRA />}
        <Modal />
        <BrowserWarning />
        <GdprBanner />
        <Userback />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
